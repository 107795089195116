<template>
  <Modal :active="active" :width="1400" class="edit-modal" @close="closeModal">
    <template slot="header">
      {{ editedPanel ? i18n.edit_panel : i18n.create_panel_from_template }}
    </template>
    <div v-if="!isPortalActive && selectedTemplate === undefined && browseOnlineRepo === false" class="content">
        <h3>{{ i18n.select_a_type_of_panel }}</h3>
        <b-loading v-if="loading" :is-full-page="false" :active="loading" />
        <div v-else>
          <a v-for="t in templates" :key="t._id" class="template-item" @click="selectedTemplate = t">
            <span class="name">{{ t.name }}</span>
            <span>{{ t.description }}</span>
            <img :src="t.image" :alt="t.name">
          </a>
          <a class="template-item custom-view" @click="createCustomView">
            <span class="name">{{ i18n.custom_view }}</span>
            <span>{{ i18n.custom_view_description }}</span>
          </a>
        </div>
    </div>
    <div v-else-if="browseOnlineRepo === true">
      <OnlineTemplateRepoViewer @goBack="browseOnlineRepo = false"/>
    </div>
    <div v-else class="content">
      <portal-target name="portalComplexField" @change="isPortalActive = $event" />
      <div :style="isPortalActive ? 'display: none': ''">
        <h3 v-if="!editedPanel">
          {{ i18n.create_a }}
          <span class="tag">
            {{ selectedTemplate.name }}
          </span>
          {{ i18n.panel }}
        </h3>
        <h3 v-else>
          {{ i18n.edit_panel }} {{ editedPanel.title }}
        </h3>
        <vue-form-generator
          :schema="selectedTemplateSchema"
          :model="newPanelFormData"
          class="form"
          tag="div"
        />
      </div>
    </div>
    <template slot="footer">
      <div style="width: 100%; display: flex;">
        <span>
          <button v-if="editedPanel" class="button" @click="showAdvancedEditor">
            <i class="mdi mdi-cog" />
            Open advanced editor
          </button>
          <button v-if="selectedTemplate !== undefined && !editedPanel" class="button is-primary" @click="selectedTemplate = undefined">
            {{ i18n.back }}
          </button>
        </span>
        <div style="flex-grow: 1;" />
        <button v-if="browseOnlineRepo === false" class="button is-primary" @click="browseOnlineRepo = true">
          <i class="mdi mdi-earth" />&nbsp;
          Parcourir les exemples en ligne
        </button>
        <div class="field" style="display: flex">
          <portal-target name="templatePanelCreatorFooter" />
          <p class="control">
            <button class="button" @click="$emit('close')">
              {{ i18n.cancel }}
            </button>
          </p>
          <p class="control" v-if="selectedTemplate !== undefined">
            <button v-if="!this.editedPanel" class="button is-primary" @click="createOrUpdate">
              {{ i18n.create }}
            </button>
            <button v-else class="button is-primary" @click="createOrUpdate">
              {{ i18n.update }}
            </button>
          </p>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import JSON5 from 'json5';
import Handlebars from 'handlebars';
import VueFormGenerator from 'vue-form-generator';
import mapObject from 'map-obj';
import { ToastProgrammatic as Toast } from 'buefy';
import Modal from '@/components/modals/Modal';
import Api from '@/core/Api';
import i18n from 'i18n/components/TemplatePanelCreator.json';
import deepClone from '@/core/utils/deepClone';
import redirect from '@/core/utils/redirect';

import PermissionManager from '@/permissions/PermissionManager';
import actions from '@/permissions/actions';

import { mapState } from 'vuex';

Handlebars.registerHelper('escapeSingleQuotes', (text) => {
  const str = text.replace(/&#x27;/g, '\'');
  return str;
});

Handlebars.registerHelper('raw', (content) => content.fn());

export default {
  name: 'TemplatePanelCreator',
  components: {
    Modal,
    VueFormGenerator: VueFormGenerator.component,
    OnlineTemplateRepoViewer: () => import(/* webpackChunkName: "OnlineTemplateRepoViewer" */ '@/components/OnlineTemplateRepoViewer'),
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    this.fetchTemplates();
    return {
      actions,
      PermissionManager,
      i18n,
      browseOnlineRepo: false,
      isPortalActive: false,
      selectedTemplate: undefined,
      loading: true,
      templates: undefined,
      newPanelFormData: {},
    };
  },
  watch: {
    selectedTemplate(t) {
      if (this.editedPanel) {
        this.newPanelFormData = deepClone(this.editedPanel);
      } else if (t && t.defaultFormValue) {
        this.newPanelFormData = { ...t.defaultFormValue };
      } else {
        this.newPanelFormData = {};
      }
    },
    editedPanel(v) {
      if (v && v._metadatas && v._metadatas.wizard) {
        this.selectedTemplate = this.templates.filter((t) => t.name === v._metadatas.wizard.name)[0];
      }
    },
  },
  computed: {
    ...mapState({
      editedPanel: (state) => state.panels.editedPanel,
      showPanelFromTemplateModal: (state) => state.config.showPanelFromTemplateModal,
    }),
    selectedTemplateSchema () {
      if (this.selectedTemplate && this.selectedTemplate.form) {
        if (typeof this.selectedTemplate.form === 'string') {
          return this.getSchemaByName(this.selectedTemplate.form);
        } else {
          return this.selectedTemplate.form;
        }
      }
    }
  },
  methods: {
    getSchemaByName(schemaName) {
      const schemas = this.$store.state.abstractElements.objects.schemas.objects;
      for (let i = 0; i < schemas.length; i++) {
        if (schemas[i].name === schemaName) {
          return schemas[i];
        }
      }
      return undefined;
    },
    showAdvancedEditor() {
      this.$store.commit('config/setShowPanelFromTemplateModal', false);
    },
    deletePanel() {
    },
    closeModal() {
      this.selectedTemplate = undefined;
      this.$emit('close');
    },
    createCustomView() {
      this.$store.commit('config/setShowPanelFromTemplateModal', false);
      this.$store.commit('panels/setAddedPanel', { __schemaStrategy : 'new' });
    },
    async fetchTemplates() {
      this.loading = true;
      const templatesPayload = await Api.get('/system_document_templates');
      this.loading = false;
      if (templatesPayload.data && templatesPayload.data.success) {
        this.templates = templatesPayload.data.documents;
      }
    },
    async createOrUpdate() {
      if (this.selectedTemplate) {
        let object = this.newPanelFormData;

        if (this.selectedTemplate.template) {
          const source = this.selectedTemplate.template;
          const template = Handlebars.compile(source);
          let templateCompiled;
          try {
            const newprops = {};
            const newPanelFormData = mapObject(this.newPanelFormData, (key, value) => {
              if (typeof value === 'object') {
                newprops[`${key}__str`] = JSON5.stringify(value);
              }
              return [key, value];
            });
            const templateParams = { ...newPanelFormData, ...newprops };
            templateCompiled = template(templateParams);
            console.log('template Compiled', templateCompiled);
            object = {
              ...this.editedPanel || {},
              ...JSON5.parse(templateCompiled),
              _metadatas: {
                ...this.editedPanel ? this.editedPanel._metadatas || {} : {},
                wizard: { name: this.selectedTemplate.name, params: { ...newPanelFormData, ...newprops } },
              },
            };
          } catch (e) {
            Toast.open({
              message: i18n.error_at_creation_incorrect_generated_view,
              type: 'is-danger',
            });

            console.error('incorrect JSON', templateCompiled, e);
            return;
          }
        }
        let panelPayload;
        if (!this.editedPanel) {
          panelPayload = await this.$store.dispatch('abstractElements/createObject', { collection: this.selectedTemplate.collection || 'panels', object });
        } else {
          panelPayload = await this.$store.dispatch('abstractElements/saveObject', { collection: this.selectedTemplate.collection || 'panels', object });
        }
        panelPayload = panelPayload.payload || panelPayload;
        if (panelPayload.data.success) {
          if (this.selectedTemplate.goToView) {
            redirect(this.$router, [`/panel/${panelPayload.data.document._id}`]);
          }
          this.closeModal();
        }
      }
    },
  },
};
</script>
<style scoped>
.content {
  padding: 40px;
}

.template-item {
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  color: #AAA;
  position: relative;
  width: 100%;
  display: block;
  height: 150px;
  padding: 20px;
}
.template-item:hover {
  background: #EFEFEF;
}
.template-item .name {
  display: block;
  color: #999;
  font-size: 200%;
  padding-top: 10px;
}
.template-item img {
  position: absolute;
  right: 20px;
  top: 10px;
  max-height: 130px;
}
</style>
